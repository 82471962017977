import React, {useEffect, useState} from 'react';
import dayjs from "dayjs";
import axios from "axios";
import {DatePicker, Row, Space, Switch} from "antd";
import Matches from "./Matches";
import {STREAM_SERVER} from "./Cakhia";

const Vebo = ({provider}) => {
    const [selectedDate, setSelectedDate] = useState(dayjs().format("YYYYMMDD"));
    const [showFeatured, setShowFeatured] = useState(true);
    const [matches, setMatches] = useState([]);
    const [loading, setLoading] = useState(false);
    const [referer, setReferer] = useState('');

    const getSettings = async () => {
        const response = await axios.get(`${STREAM_SERVER}/settings`);
        setReferer(response.data.referer);
    }

    const getAllMatches = async () => {
        setLoading(true)
        const response = await axios.get(`https://api.vebo.xyz/api/match/fixture/${selectedDate}`);
        setMatches(response.data?.data)
        setLoading(false)
    }

    const getFeaturedMatch = async () => {
        setLoading(true)
        const response = await axios.get(`https://api.vebo.xyz/api/match/featured`);
        setMatches(response.data?.data)
        setLoading(false)
    }

    useEffect(() => {
        getSettings();
    }, []);

    useEffect(() => {
        showFeatured ? getFeaturedMatch() : getAllMatches();
    }, [selectedDate, showFeatured, provider]);

    return (
        <div>
            <div className="App" style={{marginTop: 16}}>
                <Row style={{marginLeft:7,marginBottom:4}} align={"middle"}>
                    <Space align="center" style={{marginRight: 16}}>
                        Featured Only: <Switch value={showFeatured}
                                               onChange={setShowFeatured}/>
                    </Space>
                    {!showFeatured && <DatePicker onChange={(date) => {
                        setSelectedDate(date.format("YYYYMMDD"))
                    }} defaultValue={dayjs()}/>}
                </Row>

            </div>

            {/*<MatchList matches={matches.filter(m => m.sport_type == "football")} referer={"https://watch.ezyproxy.com/"} loading={loading}/>*/}
            <Matches matches={matches.filter(m => m.sport_type == "football")} referer={referer}
                     loading={loading} isVebo={true}/>
        </div>
    );
};

export default Vebo;
